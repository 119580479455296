@import '../../Styles/variables.scss';

.layout-sider-container {
  background-color: $background-color;
  height: 100vh;

  .country-flag {
    margin-top: 27px;
    margin-left: -8px;
  }

  .country-name {
    font-size: 0.65rem;
    color: $logo-text-color;
    margin-top: -5px;
    font-family: 'MuseoSans';
  }

  .toggle-nav-btn {
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: calc(100% - 20px);
    border: 1px solid $primary-color;
    color: $primary-color;
    border-radius: 5px;
    height: 32px;
    vertical-align: middle;
    padding: 0px;
    font-size: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .layout-sider-menu-container {
    background-color: $background-color;
    padding-top: 12px;

    .ant-menu-inline-collapsed {
      .ant-menu-item-icon {
        line-height: 46px !important;
      }
    }
  }

  .layout-sider-div-container {
    padding-top: 22px;

    .layout-sider-heading-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0rem 2rem 0 1rem;
      cursor: pointer;

      .title {
        font-family: 'MuseoSans';
        font-size: 1.2rem;
        color: $logo-text-color;
        font-weight: 700;
        margin-right: 0.5rem;
      }

      .title-sub {
        font-weight: 100 !important;
        font-family: 'MuseoSans';
        font-size: 1.2rem;
        color: $logo-text-color;
      }

      .logo {
        height: 52px;
        width: 80px;

        img {
          object-fit: cover;
          height: auto;
          height: 78%;
          margin-top: 6px;
          margin-right: 5px;
        }
      }
    }

    .ant-menu-item-selected {
      background: linear-gradient(to right, $gradient-background-start, $gradient-background-end);
      color: $dark-text-color;
      border-top-right-radius: 22px;
      border-bottom-right-radius: 22px;

      a {
        color: $dark-text-color;
      }
    }

    .ant-menu-item::after {
      border: none;
    }

    .ant-menu-submenu-selected {
      .ant-menu-item::after {
        border-left: 2px solid $primary-color;
      }

      .ant-menu-submenu-title {
        background: linear-gradient(to right, $gradient-background-start, $gradient-background-end);
        color: $dark-text-color;
        border-top-right-radius: 22px;
        border-bottom-right-radius: 22px;

        .ant-menu-submenu-arrow {
          color: $dark-text-color;
        }

        a {
          color: $dark-text-color;
        }
      }

      .ant-menu-item-selected {
        background: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        color: $primary-color !important;

        a {
          color: $primary-color !important;
        }
      }
    }

    .ant-menu-item {
      height: 44px;
      padding-left: 1.3rem;
    }

    .ant-menu-submenu-title {
      height: 44px;
    }
  }
}
