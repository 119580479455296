@import '../../Assets/Fonts/fonts.scss';
@import '../../Styles/variables.scss';

.homepage-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $background-color;
  scroll-behavior: smooth;

  .homepage-header-container {
    background-color: rgba(255, 255, 255, 0.8);
    height: 90px;
    padding-top: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 0rem 0 0vw;
    cursor: pointer;

    html {
      scroll-behavior: smooth;
    }

    .title {
      font-family: 'MuseoSans';
      font-size: 1.2rem;
      color: $logo-text-color;
      font-weight: 700;
      margin-right: 0.5rem;
      padding-left: 5px;
    }

    .title-sub {
      font-weight: 100 !important;
      font-family: 'MuseoSans';
      font-size: 1.2rem;
      color: $logo-text-color;
    }

    .logo {
      height: 40px;
      padding-left: 2vw;

      img {
        object-fit: cover;
        height: 40px;
        width: 40px;
      }
    }

    .country-name {
      font-size: 0.65rem;
      color: $logo-text-color;
      margin-top: -5px;
      font-family: 'MuseoSans';
      margin-left: 6px;
    }
  }

  .homepage-button-container {
    display: flex;
    justify-content: right;
    height: 90px;
    background-color: rgba(255, 255, 255, 0.8);

    button {
      margin-top: 29px;
      margin-right: 30px;
      margin-left: 30px;
      text-transform: uppercase;

      @media (max-width: 678px) {
        margin-right: 2vw;
      }
    }

    .ant-btn-primary {
      background: linear-gradient(to right, #1890ff, $gradient-background-end);
      color: $dark-text-color;
      border: none;
    }

    .ant-btn-primary:hover {
      background: linear-gradient(to right, $gradient-background-end, #1890ff);
    }
  }

  .image-container {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.3) 10%,
        rgba(22, 177, 255, 0.4) 40%
      ),
      url('../../Assets/Images/factory.webp');
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.3) 10%,
        rgba(22, 177, 255, 0.4) 40%
      ),
      image-set('../../Assets/Images/P4.jpg' type('image/jpeg'));
    background-size: cover;
  }

  .homepage-img-container {
    height: 100vh;
    background-position-x: 100%;
    background-position-y: 100%;
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    flex: 1;
    text-align: left;

    .header-row {
      @media (max-width: 536px) {
        flex-flow: row;
      }
    }

    .arrows {
      width: 60px;
      height: 72px;
      position: absolute;
      left: 50%;
      margin-left: -30px;
      bottom: 20px;

      @media (max-width: $lg-size) {
        stroke-width: 2px;
        width: 30px;
        height: 36px;
      }
    }

    .arrows path {
      stroke: #ffffff;
      fill: transparent;
      stroke-width: 2.5px;
      animation: arrow 2s infinite;
      -webkit-animation: arrow 2s infinite;

      @media (max-width: $lg-size) {
        stroke-width: 2px;
      }
    }

    @keyframes arrow {
      0% {
        opacity: 0;
      }

      40% {
        opacity: 1;
      }

      80% {
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }

    @-webkit-keyframes arrow

    /*Safari and Chrome*/ {
      0% {
        opacity: 0;
      }

      40% {
        opacity: 1;
      }

      80% {
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }

    .arrows path.a1 {
      animation-delay: -1s;
      -webkit-animation-delay: -1s;
    }

    .arrows path.a2 {
      animation-delay: -0.5s;
      -webkit-animation-delay: -0.5s;
    }

    .arrows path.a3 {
      animation-delay: 0s;
      -webkit-animation-delay: 0s;
    }

    .text-ctn {
      margin-left: 2vw;
      bottom: 50px;
      font-family: $secondary-font-family;
      font-size: 4rem;
      color: $outside-background-color;
      line-height: 1.2em;
      text-transform: uppercase;
      position: relative;
      padding-top: 60vh;

      @media (max-width: 1800px) {
        font-size: 3rem;
        margin-top: 1rem;
        margin-bottom: unset;
        line-height: 1.5em;
        padding-top: 50vh;
      }

      @media (max-width: 579px) {
        font-size: 2.2rem;
        padding-top: 30vh;
        text-align: left;
      }

      @media (max-width: 427px) {
        font-size: 2.2rem;
        padding-top: 30vh;
        text-align: center;
      }

      @media (max-width: 380px) {
        font-size: 2rem;
        padding-top: 30vh;
        text-align: center;
      }
    }

    .subhome {
      text-align: left;
      font-family: $secondary-font-family;
      font-size: 1.5rem;
      color: $outside-background-color;
      line-height: 2rem;
      text-transform: uppercase;
      margin-top: 20px;

      @media (max-width: $lg-size) {
        justify-content: center;
      }

      @media (max-width: 579px) {
        text-align: left;
        font-size: 1.2rem;
      }

      @media (max-width: 427px) {
        text-align: center;
        font-size: 1.2rem;
      }
    }
  }

  .homepage-content-containerwhite {
    background-color: $outside-background-color;
    height: fit-content;
    padding: 10px 60px 10px 60px;
    text-align: center;
    margin-top: 5px;

    @media (max-width: $lg-size) {
      height: fit-content;
    }

    @media (max-width: 536px) {
      padding: 10px 0 10px 0;
    }

    .title {
      font-size: 2rem;
      font-weight: 700;
      font-family: $primary-font-family;
      color: $title-text-color;
      text-align: center;
      justify-content: center;
      text-transform: uppercase;
      padding: 50px 50px 10px 50px;

      @media (max-width: $lg-size) {
        font-size: 2rem;
        margin-top: 1rem;
        color: $title-text-color;
        line-height: 1.5em;
      }
    }

    .homepagebody {
      text-align: center;
      font-size: 0.875rem;
      font-weight: 400;
      font-family: $primary-font-family;
      color: $title-text-color;
      justify-content: center;
      margin: 20px 60px 20px 60px;

      @media (max-width: $lg-size) {
        font-size: 0.8rem;
        margin-top: 1rem;
        color: $title-text-color;
        line-height: 1.5em;
      }

      .homepagebody_aboutusline1 {
        font-size: 1.125rem;
        margin-top: 1rem;
        color: $title-text-color;

        @media (max-width: $lg-size) {
          font-size: 1rem;
          margin-top: 1rem;
          color: $title-text-color;
          line-height: 1.5em;
        }

        @media (max-width: 1200px) {
          margin-top: 3rem;
        }
      }

      .homepagebody_aboutuslines {
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content: center;
        align-items: center;
        font-size: 1.125rem;

        @media (max-width: $lg-size) {
          font-size: 1rem;
          margin-top: 1rem;
          color: $title-text-color;
          line-height: 1.5em;
        }

        @media (max-width: 1200px) {
          margin-top: 3rem;
        }
      }

      .homepagebody_logoaboutuslines {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      .homepagebody_subtitle {
        font-size: 1.25rem;
        font-weight: 700;
        font-family: $primary-font-family;
        color: $title-text-color;
        text-align: center;
        justify-content: center;
        margin-top: 2rem;
      }
    }

    .aboutus-card-main-container {
      display: flex;
      height: 26rem;
      min-height: 20rem;
      max-width: 26rem;
      justify-content: center;
      background: linear-gradient(to right, $gradient-background-start, $gradient-background-end);
      border-radius: 0.625rem;
      padding: 40px 25px 25px 25px;
      margin: 60px 40px 60px 40px;
      text-align: center;

      @media (max-width: 1350px) {
        height: 28rem;
        max-width: 18rem;
        padding: 30px 5px 25px 5px;
      }

      @media (max-width: 1050px) {
        max-width: 18rem;
        padding: 30px 5px 25px 5px;
      }

      @media (max-width: 986px) {
        height: 28rem;
        max-width: 18rem;
        padding: 30px 5px 25px 5px;
      }

      @media (max-width: 764px) {
        margin-bottom: -10px;
      }

      @media (max-width: 536px) {
        padding: 40px 5px 25px 5px;
        margin: 60px 0 60px 0;
      }
    }

    .aboutus-card-main-container {
      @media (max-width: 1200px) {
        margin-bottom: 0;
      }
    }

    .aboutus-card-main-container:hover {
      background: $gradient-background-end;
      cursor: pointer;
    }

    .aboutus_cards-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;

      .aboutus_card-row {
        display: flex;
        align-items: center;
        justify-content: center;

        .aboutus_card-col {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }

      .aboutusIcon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .aboutus-card-text {
        color: $dark-text-color;
        line-height: 2;
      }

      .aboutus-card-title {
        font-weight: 700;
        display: flex;
        color: $dark-text-color;
        line-height: 2;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }

    .undplogocontainer {
      padding-top: 40px;

      .align-to-end {
        @media (max-width: 767px) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 20px;
        }
      }

      .align-to-start {
        @media (max-width: 767px) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 20px;
        }
      }

      .erbd {
        width: 14.5rem;
      }

      .undp {
        width: 4rem;
      }

      .unfccc {
        width: 7.5rem;
      }

      .ieta {
        width: 14.5rem;

        @media (max-width: 876px) {
          width: 12rem;
        }

        @media (max-width: 820px) {
          width: 11rem;
        }

        @media (max-width: 750px) {
          width: 14.5rem;
        }
      }

      .esa {
        width: 14.5rem;

        @media (max-width: 876px) {
          width: 11rem;
        }

        @media (max-width: 820px) {
          width: 10rem;
        }

        @media (max-width: 750px) {
          width: 12.5rem;
        }
      }

      .wbank {
        width: 7.5rem;
      }
    }
  }

  .aboutusicon {
    size: 100px;
  }

  .eligicontent {
    padding: 20px;
  }

  .homepage-image-content-container {
    background-color: $outside-background-color;
    margin-top: 5px;

    @media (max-width: 767px) {
      padding-left: 0;
    }

    .forest-image {
      height: 33.75rem;
      width: 58.5rem;

      @media (max-width: 767px) {
        display: none;
      }
    }

    ul {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    li {
      line-height: 40px;

      @media (max-width: 536px) {
        line-height: 30px;
      }
    }

    .title {
      font-size: 2rem;
      font-weight: 700;
      font-family: $primary-font-family;
      color: $title-text-color;
      text-align: left;
      justify-content: left;
      text-transform: uppercase;
      margin-left: 4.5rem;
      padding: 50px 50px 10px 0;

      @media (max-width: $lg-size) {
        font-size: 2rem;
        margin-top: 1rem;
        color: $title-text-color;
        line-height: 1.5em;
      }

      @media (max-width: 768px) {
        text-align: left;
        justify-content: left;
        padding: 50px 50px 10px 0;
        margin-left: 2rem;
      }
    }

    .homepagebody {
      text-align: left;
      font-size: 0.875rem;
      font-weight: 400;
      font-family: $primary-font-family;
      color: $title-text-color;
      justify-content: left;
      margin: 20px 10px 20px 75px;

      @media (max-width: $lg-size) {
        font-size: 0.8rem;
        margin-top: 1rem;
        color: $title-text-color;
        line-height: 1.5em;
      }

      @media (max-width: 768px) {
        margin: 20px 30px 20px 32px;
      }
    }
  }

  .homepage-resources-content-container {
    background-color: $outside-background-color;
    height: fit-content;
    margin-top: 5px;
    height: fit-content;

    @media (max-width: $lg-size) {
      height: fit-content;
    }

    ul {
      margin-top: 25px;
      margin-left: 4rem;
    }

    li {
      line-height: 40px;

      @media (max-width: 536px) {
        line-height: 30px;
      }
    }

    img {
      display: flex;
      padding: 50px;
      justify-content: center;
      text-align: center;
      width: 28.125rem;
      height: 23.438rem;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .resource-image-container {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .title {
      font-size: 2rem;
      font-weight: 700;
      font-family: $primary-font-family;
      color: $title-text-color;
      text-transform: uppercase;
      padding: 50px 50px 10px 0;

      @media (max-width: $lg-size) {
        font-size: 2rem;
        color: $title-text-color;
        line-height: 1.5em;
      }

      @media (max-width: 768px) {
        text-align: left;
        margin-left: 3rem;
        padding: 50px 50px 10px 0;
      }
    }

    .homepagebody {
      font-size: 0.875rem;
      font-weight: 400;
      font-family: $primary-font-family;
      color: $title-text-color;
      margin: 20px 60px 20px -80px;

      @media (max-width: $lg-size) {
        font-size: 0.8rem;
        color: $title-text-color;
        line-height: 1.5em;
      }

      @media (max-width: 768px) {
        margin: 20px 30px 20px -40px;
      }
    }
  }
}
